<script>
import ElementCard from '../components/ElementCard'

import {lastUpdates} from '../modules/sortedMethods'

import jsonMods from '../data/dataMods.json'
import jsonPoses from '../data/dataPoses.json'

export default {
  components: {ElementCard},
    props: {
        nsfwFilter: {type: String}
    },
  data() {
    return {
      lastSfwUpdates: lastUpdates([jsonMods.data,jsonPoses.data],this.nsfwFilter)
      }
  },
}

</script>

<template>
  <section>
    <div id="home">
      <h2>Welcome on Moon Sea</h2>
      <p>If you're here, you probably know what I'm doing. As a final fantasy lover and a gpose addict, I'm making poses and mods. I post sfw and nsfw content accross several platforms.<br><br>
            This website is here to link to those platforms. I post some screenshots on Twitter and Instagram. My poses and mods are posted on XMA. If you like my content, please, consider helping me through Ko-fi or Patreon. I take commissions too.<br><br>
            If you just want to chat, come on my discord 😉<br></p>
      <h3>Last updates :</h3>
      <ElementCard v-bind:dataArray="lastSfwUpdates" />
    </div>
  </section>
</template>

<style>
  #home {
    padding: 0 3vh;
    background-color: rgba(72, 88, 116, 0.5);
    box-shadow: 0 0 5px 0 rgba(72, 88, 116, 0.5);
    border-radius: 0.5em;
    max-width: 700px;
  }
</style>